import React from 'react';

import * as S from './styles';

interface InputFieldProps {
  formik: any;
  type: string;
  id: string;
  name: string;
  placeholder: string;
  showType: string;
}

export interface IErrorMessages {
  errors?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  formik,
  type,
  id,
  name,
  placeholder,
  showType
}) => {
  return (
    <S.Div>
      {showType !== 'message' ? (
        <S.Input type={type} id={id} name={name} placeholder={placeholder} />
      ) : (
        <S.TextareaAutosize
          onChange={formik.handleChange}
          name={name}
          value={formik.values.message}
          placeholder={placeholder}
        />
      )}

      <S.ErrorMessagesList errors={formik.errors[name]}>
        <li key={id}>This field is requered</li>
      </S.ErrorMessagesList>
    </S.Div>
  );
};

export default InputField;
