import styled from '@emotion/styled';

import Image, { IImage } from 'components/Image';

interface IContentWrapperProps {
  rightImage?: string;
  leftImage?: string;
  imageMargin: number;
}

type ContentBoxProps = {
  shouldReverse: boolean;
};
export const ContentBox = styled.div<ContentBoxProps>`
  background: ${({ theme }) => theme.colors.secondary.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  word-break: break-word;

  ${({ theme }) => theme.breakpoints.lg} {
    flex-direction: ${(props) => (props.shouldReverse ? `column-reverse` : 'column')};
  }
`;

interface IContentImageProps extends IImage {
  imageMargin: number;
}

export const ContentImage = styled(Image)<IContentImageProps>`
  object-fit: contain;
  ${(props) => `margin: ${props.imageMargin}px;`};
  ${({ theme }) => theme.breakpoints.lg} {
    margin: 0 0 1rem 0;
    width: 40%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  ${(props: IContentWrapperProps) =>
    props.rightImage && `margin-right: ${props.imageMargin * -1}px;`};
  ${(props: IContentWrapperProps) =>
    props.leftImage && `margin-left: ${props.imageMargin * -1}px;`};
  ${({ theme }) => theme.breakpoints.lg} {
    margin: 0;
  }
  width: 100%;
`;

interface IBorderWrapperProps {
  marginTop: number;
  marginBottom: number;
}

export const BorderWrapper = styled.div`
  background: ${({ theme }) => `linear-gradient(to right,
        ${theme.colors.primary.orange} 15px,
        transparent 15px
      )
      0 0,
    linear-gradient(to right, ${theme.colors.primary.orange} 15px, transparent 15px) 0 100%,
    linear-gradient(to left, ${theme.colors.primary.orange} 15px, transparent 15px) 100% 0,
    linear-gradient(to left, ${theme.colors.primary.orange} 15px, transparent 15px) 100% 100%,
    linear-gradient(to bottom, ${theme.colors.primary.orange} 15px, transparent 15px) 0 0,
    linear-gradient(to bottom, ${theme.colors.primary.orange} 15px, transparent 15px) 100% 0,
    linear-gradient(to top, ${theme.colors.primary.orange} 15px, transparent 15px) 0 100%,
    linear-gradient(to top, ${theme.colors.primary.orange} 15px, transparent 15px) 100% 100%`};
  background-repeat: no-repeat;
  background-size: 50px 100%;
  padding: 15px;
  margin: ${(props: IBorderWrapperProps) => props.marginTop}px auto
    ${(props) => props.marginBottom}px auto;
`;
