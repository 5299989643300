import styled from '@emotion/styled';

import Image from 'components/Image';

export const PageHeader = styled.div`
  background: ${({ theme }) => theme.colors.secondary.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: #fff;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column-reverse;
  }
`;

export const HeaderImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.md} {
    width: 40%;
    height: auto;
  }
`;
