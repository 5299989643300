import styled from '@emotion/styled';

import Image from 'components/Image';

export const CaseStudyText = styled.div`
  border: 1px solid #707070;
  background: #f8f8f8;
  padding: 2rem;
  width: 700px;
  margin: -150px 0;

  ${({ theme }) => theme.breakpoints.lg} {
    margin: 40px 0 0 0;
    width: 100%;
  }
`;

export const Quote = styled(Image)`
  margin-top: -60px;
  margin-left: -60px;
  position: absolute;

  ${({ theme }) => theme.breakpoints.lg} {
    margin-top: -45px;
    margin-left: -40px;
    width: 60px;
    height: 60px;
  }
`;
