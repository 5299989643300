const headers = {
  'Content-Type': 'application/json'
};

export interface ISubmitContactFormInput {
  name: string;
  email: string;
  message: string;
  captchaToken: string;
}

export const submitContactForm = async (input: ISubmitContactFormInput) => {
  return await fetch('/api/contactForm', {
    method: 'POST',
    headers,
    body: JSON.stringify(input)
  });
};
