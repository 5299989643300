import { ReactNode } from 'react';

import { isEmptyDocument } from 'datocms-structured-text-utils';

import DatoStructuredText from 'components/DatoStructuredText';
import QuoteBox from 'components/QuoteBox';

import * as S from './styles';

interface IContentBoxNewProps {
  children: ReactNode;
  leftImageSrc?: string;
  rightImageSrc?: string;
  imageSize?: number;
  imageMargin?: number;
  marginTop?: number;
  marginBottom?: number;
  quoteBox?: any;
}

const ContentBoxNew = ({
  children,
  leftImageSrc,
  rightImageSrc,
  imageSize = 320,
  imageMargin = -50,
  marginTop = 50,
  marginBottom = 50,
  quoteBox
}: IContentBoxNewProps) => {
  return (
    <S.BorderWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <S.ContentBox shouldReverse={Boolean(rightImageSrc)}>
        {leftImageSrc && (
          <S.ContentImage
            src={leftImageSrc}
            height={imageSize}
            width={imageSize}
            imageMargin={imageMargin}
            key={leftImageSrc}
          />
        )}

        <S.ContentWrapper
          rightImage={rightImageSrc}
          leftImage={leftImageSrc}
          imageMargin={imageMargin}
        >
          {children}
        </S.ContentWrapper>

        {rightImageSrc && isEmptyDocument(quoteBox) && (
          <S.ContentImage
            src={rightImageSrc}
            height={imageSize}
            width={imageSize}
            imageMargin={imageMargin}
            key={rightImageSrc}
          />
        )}
        {!isEmptyDocument(quoteBox) && (
          <QuoteBox>
            <DatoStructuredText data={quoteBox} />
          </QuoteBox>
        )}
      </S.ContentBox>
    </S.BorderWrapper>
  );
};

export default ContentBoxNew;
