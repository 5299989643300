import { ReactNode } from 'react';

import * as S from './styles';

interface IQuoteBoxProps {
  children: ReactNode;
}

const QuoteBox = ({ children }: IQuoteBoxProps) => {
  return (
    <S.CaseStudyText>
      <div>
        <S.Quote src='/quote.png' width={80} height={80} />
      </div>
      {children}
    </S.CaseStudyText>
  );
};

export default QuoteBox;
