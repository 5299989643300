import {
  ContentFormRecordFragment,
  HeaderRecordFragment,
  NavItemFragment,
  PageContentFragment,
  SeoFragment
} from 'graphql/generated';

import ContactForm from 'components/ContactForm';
import ContentBoxNew from 'components/ContentBoxNew';
import DatoMeta from 'components/DatoMeta';
import DatoStructuredText from 'components/DatoStructuredText';
import Footer from 'components/Footer';
import Navigation from 'components/Navigation';
import { PageHeader } from 'components/PageHeader';
import RootContainer from 'components/RootContainer';

interface IDatoPageProps {
  seo?: SeoFragment;
  contentBoxes?: (PageContentFragment | HeaderRecordFragment | ContentFormRecordFragment)[];
  navItems: NavItemFragment[];
}

const DatoPage = ({ seo, contentBoxes, navItems }: IDatoPageProps) => {
  return (
    <>
      {seo && <DatoMeta seo={seo} />}

      <Navigation {...{ navItems }} />

      {contentBoxes?.map((content, i) => {
        if (content.__typename === 'ContentBlockRecord') {
          return (
            <RootContainer key={i}>
              <ContentBoxNew
                leftImageSrc={content.leftImage?.url}
                rightImageSrc={content.rightImage?.url}
                imageSize={content.imageSize}
                imageMargin={content.imageMargin}
                quoteBox={content.quoteBox}
              >
                <DatoStructuredText data={content.content} />
              </ContentBoxNew>
            </RootContainer>
          );
        }
        if (content.__typename === 'HeaderRecord') {
          return <PageHeader data={content.content} image={content.image} key={i} />;
        }
        if (content.__typename === 'ContactFormRecord') {
          return (
            <RootContainer key={i}>
              <ContentBoxNew>
                <ContactForm content={content} />
              </ContentBoxNew>
            </RootContainer>
          );
        }
      })}

      <Footer />
    </>
  );
};

export default DatoPage;
