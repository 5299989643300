import DatoStructuredText from 'components/DatoStructuredText';
import RootContainer from 'components/RootContainer';

import * as S from './styles';

interface IPageHeaderProps {
  data: any;
  image?: {
    url: string;
    responsiveImage?: {
      aspectRatio: number;
    };
  };
}

export const PageHeader = ({ data, image }: IPageHeaderProps) => {
  return (
    <S.PageHeader>
      <RootContainer>
        <S.Layout>
          <div>
            <DatoStructuredText data={data} />
          </div>
          {image && image.responsiveImage && (
            <S.HeaderImage
              src={image.url}
              width={350 * image.responsiveImage.aspectRatio}
              height={350}
              key={image.url}
            />
          )}
        </S.Layout>
      </RootContainer>
    </S.PageHeader>
  );
};
