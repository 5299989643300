import ReCAPTCHA from 'react-google-recaptcha';

import styled from '@emotion/styled';

import { IErrorCaptchaCheck } from '.';
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const Title = styled.h3`
  color: #0a0a0a;
  font-family: Montserrat;
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Div = styled.div`
  display: flex;
  gap: 2.5rem;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const flexDiv = styled.div`
  margin-top: 3rem;
  @media (max-width: 550px) {
    margin-top: 1.5rem;
  }
`;

export const InfoText = styled.p`
  color: #000;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3rem;
`;

export const StyleLabel = styled.div`
  color: #1b1b1b;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyleReCAPTCHA = styled(ReCAPTCHA)`
  margin-bottom: 2.5rem;
`;

export const ErrorMessagesList = styled.ul<IErrorCaptchaCheck>`
  color: red;
  opacity: ${(props) => (props.error === false ? '0' : '1')};
  transition: opacity 1s;
  margin-top: -2rem;
  margin-bottom: 0.3rem;
`;
