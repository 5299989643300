'use client';

import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { useRouter } from 'next/router';

import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/Button';
import DatoStructuredText from 'components/DatoStructuredText';
import InputField from 'components/InputField';

import { submitContactForm } from 'lib/nextSDK';

import * as S from './styles';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email('Please enter a valid email').required('This field is required'),
  message: Yup.string().required('This field is required')
});

const initialValues = {
  name: '',
  email: '',
  message: '',
  captchaToken: ''
};

export interface IErrorCaptchaCheck {
  error: boolean;
}

const ContactForm = ({ content }: any) => {
  const router = useRouter();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isCaptchaFailed, setIsCaptchaFailed] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      if (recaptchaRef.current?.getValue()) {
        const response = await submitContactForm(values);
        if (response.ok) {
          recaptchaRef?.current?.reset();
          router.push(`/${content.redirectTo.slug}`);
        }
        resetForm();
      }
      if (!values.captchaToken) {
        setIsCaptchaFailed(true);
      }
    }
  });

  return (
    <div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} action=''>
          <DatoStructuredText data={content.content} />
          <S.flexDiv>
            <S.Div>
              <InputField
                formik={formik}
                type='text'
                id='name'
                name='name'
                placeholder='Name'
                showType='input'
              />
            </S.Div>
          </S.flexDiv>
          <S.flexDiv>
            <S.Div>
              <InputField
                formik={formik}
                type='email'
                id='email'
                name='email'
                placeholder='Email'
                showType='input'
              />
            </S.Div>
          </S.flexDiv>
          <S.flexDiv>
            <InputField
              formik={formik}
              type='text'
              id='message'
              name='message'
              placeholder='What are you looking for?'
              showType='message'
            />
            <S.InfoText>
              By submitting your inquiry, you confirm that you have read the Privacy Policy and give
              your consent to have your personal information processed for the purpose of receiving
              feedback.
            </S.InfoText>
            <div>
              <S.StyleReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
                onChange={(token) => {
                  formik.handleChange({
                    target: { name: 'captchaToken', value: token }
                  });
                }}
              />

              <S.ErrorMessagesList error={isCaptchaFailed}>
                <li key={1}>Please complete the captcha</li>
              </S.ErrorMessagesList>

              <Button type='submit'>Send</Button>
            </div>
          </S.flexDiv>
        </form>
      </FormikProvider>
    </div>
  );
};

export default ContactForm;
