import reactTextareaAutosize from 'react-textarea-autosize';

import styled from '@emotion/styled';
import { Field } from 'formik';
import _ from 'lodash';

import { IErrorMessages } from '.';

export const Div = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2.5 rem;
`;

export const ErrorMessagesList = styled.ul<IErrorMessages>`
  color: red;
  opacity: ${(props) => (_.isNil(props.errors) ? '0' : '1')};
  transition: opacity 1s;
  margin-top: 0.1rem;
  margin-bottom: -3rem;
`;

export const TextareaAutosize = styled(reactTextareaAutosize)`
  padding: 10px 20px;
  border: 2px solid #fd7500;
  border-radius: 0;
  font-size: 1rem;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: #1b1b1b;
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-height: 10rem;
  max-height: 30rem;
  resize: none;
  &::placeholder {
    color: #808080;
  }
  &:focus {
    outline: none;
    border: 2px solid #1b1b1b;
  }
`;

export const Input = styled(Field)`
  padding: 10px 20px;
  border: 2px solid #fd7500;
  border-radius: 0;
  font-size: 1rem;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #1b1b1b;
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &::placeholder {
    color: #808080;
  }
  &:focus {
    outline: none;
    border: 2px solid #1b1b1b;
  }
`;
