import Head from 'next/head';

interface IDatoMeta {
  seo: {
    title?: string;
    description?: string;
    image?: {
      url: string;
    };
  };
}

const DatoMeta = ({ seo }: IDatoMeta) => {
  return (
    <Head>
      <title>{seo.title}</title>
      <link rel='shortcut icon' href='/favicon.png' />
      <meta name='description' content={seo.description} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:locale' content='en_US' />
      {seo.image && <meta property='og:image' content={seo.image.url} />}

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:site' content='@HackSoft_' />
      {seo.image && <meta name='twitter:image' content={seo.image.url} />}
      <meta name='twitter:creator' content='@HackSoft_' />
    </Head>
  );
};

export default DatoMeta;
